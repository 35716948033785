import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

export default ({
    threshold = 0.15,
    triggerOnce = true,
    distance = 100,
    delay = 0,
    from = 'right',
    className,
    children,
    Tag = 'div'
}) => {
    const [ref, inView] = useInView({ threshold, triggerOnce })

    /*
    useEffect(() => {
        if (inView) {
            console.log('a')
            //ref.current?.play()
        } else {
            console.log('b')
            //ref.current?.pause()
        }
    }, [ref, inView])
    */

    const getStyle = () => {
        switch (from) {
            case 'bottom':
                return `translate3d(0,${inView ? 0 : distance}px,0)`
                break
            case 'left':
                return `translate3d(-${inView ? 0 : distance}px,0,0)`
                break
            default:
                return `translate3d(${inView ? 0 : distance}px,0,0)`
        }
    }

    return (
        <Tag
            className={className}
            style={{
                transition: `opacity 0.5s ${delay}s ease-out, transform 0.5s ${delay}s ease-out`,
                opacity: inView ? 1 : 0,
                transform: getStyle()
            }}
            ref={ref}
        >
            {children}
        </Tag>
    )
}
