import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Svg from '../components/svg'
import Layout from '../components/layout'
import Reveal from '../components/reveal'

export default ({ data, location }) => {
    const siteTitle =
        data.site.siteMetadata?.title ||
        `We help businesses thrive in the digital world`

    const description =
        'We believe that any successful project should start with a successful conversation. So we always begin by figuring out what makes you and your goals unique. As a team of seasoned professionals, we may have hundreds of completed projects in our portfolio, but well never assume yours is just business as usual.'

    return (
        <Layout location={location} title={siteTitle} description={description}>
            <section className="section section-hero">
                <Reveal className="wrap">
                    <div className="container">
                        <h1 className="label">Who we are</h1>
                        <h2 className="title">
                            We help businesses thrive in the digital world
                        </h2>
                        <p>
                            We believe that any successful project should start
                            with a successful conversation. So we always begin
                            by figuring out what makes you and your goals
                            unique. As a team of seasoned professionals, we may
                            have hundreds of completed projects in our
                            portfolio, but well never assume yours is just
                            business as usual.
                        </p>
                        <a href="#" className="button">
                            More about us
                        </a>
                    </div>
                </Reveal>
            </section>
            <section className="section">
                <div className="wrap">
                    <div className="text-image">
                        <Reveal className="text">
                            <h2 className="label">What we do</h2>
                            <Img
                                loading="auto"
                                className="desktop-hide"
                                fluid={data.image1.childImageSharp.fluid}
                            />
                            {/*
                            <img
                                width="1028"
                                height="1130"
                                className="desktop-hide"
                                src="img/digital-visual-identity.png"
                                alt="Digital Visual Identity"
                                title="Digital Visual Identity"
                            />
                            */}
                            <h3 className="title">Digital Visual Identity</h3>
                            <p>
                                We’ll create all the tools you need to catch the
                                eye of your customer and make them want to come
                                back for more. Having a comprehensive and
                                uniform system of visual elements – form web
                                typography, to a style guide, to usage
                                guidelines – will help your brand stand out from
                                the competition, online and offline.
                            </p>
                        </Reveal>
                        <Reveal from="bottom" delay="0.2" className="img">
                            <Img
                                loading="auto"
                                className="mobile-hide"
                                fluid={data.image1.childImageSharp.fluid}
                            />
                            {/*
                            <img
                                width="1028"
                                height="1130"
                                className="mobile-hide"
                                src="img/digital-visual-identity.png"
                                alt="Digital Visual Identity"
                                title="Digital Visual Identity"
                            />
                            */}
                        </Reveal>
                    </div>
                </div>
            </section>
            <section className="section section-grey">
                <div className="wrap">
                    <div className="text-image text-image-center">
                        <Reveal from="bottom" delay="0.2" className="img">
                            <Img
                                loading="auto"
                                fluid={data.image2.childImageSharp.fluid}
                            />
                            {/*
                            <img
                                width="1542"
                                height="1695"
                                src="img/web-design.png"
                                alt="Web Design"
                                title="Web Design"
                            />
                            */}
                        </Reveal>
                        <Reveal className="text">
                            <h2 className="title">Web Design</h2>
                            <p>
                                We’ll create captivating content and intuitive
                                UI design that will help you build a meaningful
                                connection with your customers. Whatever
                                industry you come from, our team will translate
                                your business insights into an online experience
                                that showcases real value to your customers.
                            </p>
                        </Reveal>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="wrap">
                    <div className="text-image text-image-center reverse-image">
                        <Reveal className="text">
                            <h2 className="title">Development</h2>
                            <p>
                                We’ll make your business look and work perfect
                                on any screen. With the right tools and
                                features, your website will be easily
                                discovered, secure and enjoyable to all of your
                                customers. Leave the setup to us, or run it on
                                your own – we’ll hook you up with an easy to use
                                content management system that will make you
                                truly independent.
                            </p>
                        </Reveal>
                        <Reveal from="bottom" delay="0.2" className="img">
                            <Img
                                loading="auto"
                                fluid={data.image3.childImageSharp.fluid}
                            />
                            {/*
                            <img
                                width="1028"
                                height="843"
                                src="img/development.png"
                                alt="Development"
                                title="Development"
                            />
                            */}
                        </Reveal>
                    </div>
                </div>
            </section>
            <section className="section section-black">
                <div className="wrap">
                    <Reveal>
                        <h2 className="label">WHAT YOU GET</h2>
                    </Reveal>
                    <div className="pros">
                        <Reveal from="bottom" className="pros-item">
                            <div className="pros-svg pros-svg-1">
                                <Svg name="pros1" />
                            </div>
                            <h3>Fast load times</h3>
                            <p>Optimized to load fast, and stay fast.</p>
                        </Reveal>
                        <Reveal from="bottom" delay="0.2" className="pros-item">
                            <div className="pros-svg">
                                <Svg name="pros2" />
                            </div>
                            <h3>Accessible to all</h3>
                            <p>
                                Responsive to the diverse needs of your
                                customers.
                            </p>
                        </Reveal>
                        <Reveal from="bottom" delay="0.6" className="pros-item">
                            <div className="pros-svg">
                                <Svg name="pros3" />
                            </div>
                            <h3>Easily discovered</h3>
                            <p>
                                Visible to search engines and relevant to the
                                right users.
                            </p>
                        </Reveal>
                    </div>
                </div>
            </section>
            <section className="section section-yellow">
                <div className="wrap">
                    <Reveal className="text">
                        <h2 className="label">MEET OUR UNUSUALLY GREAT TEAM</h2>
                    </Reveal>
                    <div className="team">
                        <Reveal from="bottom" className="team-member">
                            <h3 className="small-title">Tatjana Volbeke</h3>
                            <p>Founder, Director</p>
                        </Reveal>
                        <Reveal
                            from="bottom"
                            delay="0.2"
                            className="team-member"
                        >
                            <h3 className="small-title">Edmundas Stundzius</h3>
                            <p>Product Design</p>
                        </Reveal>
                        <Reveal
                            from="bottom"
                            delay="0.4"
                            className="team-member"
                        >
                            <h3 className="small-title">Arnas Stucinskas</h3>
                            <p>Full Stack Engineering</p>
                        </Reveal>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        image1: file(relativePath: { eq: "digital-visual-identity-2.png" }) {
            childImageSharp {
                fluid(quality: 70) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
        image2: file(relativePath: { eq: "web-design-2.png" }) {
            childImageSharp {
                fluid(quality: 70) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
        image3: file(relativePath: { eq: "development-2.png" }) {
            childImageSharp {
                fluid(quality: 70) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
    }
`
