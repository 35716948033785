import React from 'react'

import Link from './link'

export default () => {
    return (
        <section className="section section-talk">
            <div className="wrap">
                {/*<h2 className="label">{"Let's talk"}</h2>*/}
                <div className="text-right">
                    <a href="mailto:ask@unlikeusual.com">ask@unlikeusual.com</a>
                    <br />
                    +1 (512) 608 6100
                </div>
            </div>
        </section>
    )
}
