import React, { useState } from 'react'

import Contact from '../components/contact'
import Contact2 from '../components/contact2'
import Footer from '../components/footer'
import Layout from '../layouts/index'
import '../less/frontend.less'

export default ({ location, title, description, children, darkerHeader }) => {
    return (
        <Layout
            title={title}
            description={description}
            darkerHeader={darkerHeader}
            location={location}
        >
            <div className="page">
                <main>{children}</main>
                {title == 'Contact' ? <Contact2 /> : <Contact />}
                <Footer />
            </div>
        </Layout>
    )
}
