import React from 'react'

import Link from './link'
import Svg from './svg'
import Reveal from '../components/reveal'

export default () => {
    return (
        <footer className="section section-footer">
            <div className="wrap">
                <div from="bottom" className="text-text text-text-credit">
                    <div className="text-left">
                        <span>© 2021 Unlike Usual. All rights reserved</span>
                        <Link to="/privacy">Privacy Policy</Link>
                        <Link to="/cookies">Cookie Policy</Link>
                        <Link to="/terms">Terms and Conditions</Link>
                    </div>
                    <div className="text-right text-right-icons">
                        <a
                            target="_blank"
                            aria-label="Unlike Usual Twitter"
                            rel="noopener"
                            href="https://twitter.com/UnlikeUsual"
                        >
                            <Svg name="twitter" />
                        </a>
                        {/*<a
                            target="_blank"
                            aria-label="Unlike Usual Facebook"
                            href="#"
                        >
                            <Svg name="facebook" />
                        </a>*/}
                        <a
                            target="_blank"
                            aria-label="Unlike Usual Instagram"
                            href="https://www.instagram.com/unlikeusual/"
                        >
                            <Svg name="instagram" />
                        </a>
                        <a
                            target="_blank"
                            aria-label="Unlike Usual LinkedIn"
                            href="https://www.linkedin.com/company/unlikeusual/"
                        >
                            <Svg name="linkedin" />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}
