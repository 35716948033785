import React from 'react'

import Link from './link'
import Reveal from '../components/reveal'

export default () => {
    return (
        <section className="section">
            <Reveal from="bottom" className="wrap">
                <div className="text-text text-text-contact">
                    <div className="text-left">
                        <h2 className="title">
                            Ready to take your business to the next level? We’re
                            ready to talk.
                        </h2>
                        <br />
                        <Link to="/contact" className="button">
                            Contact
                        </Link>
                    </div>
                    <div className="text-right text-right-spaced">
                        <a href="mailto:ask@unlikeusual.com" href="#">
                            ask@unlikeusual.com
                        </a>{' '}
                        <br />
                        +1 (512) 608 6100
                    </div>
                </div>
            </Reveal>
        </section>
    )
}
